import React from "react";
import styled from "styled-components";
import TopbarMost from "../Common/TopbarMost";
import { NewTheme } from "../Theme/Theme";
import { useNotification } from "../Notification/NotificationContext";
import moment from "moment";
import Php from "../../Backend/Php";
import HistoryTable from "./HistoryTable";

const CreateClientButton = styled.button`
  background-color: ${(props) =>
    props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
  color: ${(props) => (props.greencolor ? "#ffffff" : NewTheme.lightmain)};
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add smooth transitions */

  &:hover {
    background-color: #ffffff;
    color: ${(props) =>
      props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
    border: 1px solid
      ${(props) =>
        props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
  }
`;

const php = new Php();

export default function History() {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState([]);
  const [total, setTotal] = React.useState([]);
  const [date, setDate] = React.useState([new Date(), new Date()]);

  const [usersFilter, setUsersFilter] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState([]);

  React.useEffect(() => {
    load_history();
  }, []);

  const load_history = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        fromdate: moment(date[0]).format("YYYY-MM-DD 00:00:00"),
        todate: moment(date[1]).format("YYYY-MM-DD 23:59:59"),
        filter_admin: selectedUser?.id,
      };

      php.load_history(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setTransactionData(r.data);
          setUsersFilter(r.admins);
          setTotal(r.amount);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: 30,
        }}
      >
        <div style={{ marginRight: 30, fontWeight: "bold", fontSize: 22 }}>
          Total :
        </div>
        <div
          style={{
            fontSize: 22,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          {total}
        </div>
      </div>
    </div>
  );
  return (
    <div>
      <TopbarMost name={" History "} cmp={topworkui} />
      <HistoryTable
        loading={loading}
        reload={() => load_history()}
        tableData={transactionData}
        usersFilter={usersFilter}
        selectedUser={selectedUser}
        setSelectedUser={(e) => setSelectedUser(e)}
        date={date}
        setDate={(e) => setDate(e)}
        onSubmit={() => load_history()}
      />
    </div>
  );
}
