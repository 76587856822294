import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import Php from "../../../Backend/Php";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import { BalanceContainer, BalanceLabel } from "../../Common/Common";
import AddServiceModal from "./AddServiceModal";
import CloseModal from "./CloseModal";
import ResceduleModal from "./RescheduleModal";

const height = window.innerHeight;
const php = new Php();

export default function ServiceModal({
  position,
  passData,
  setVisible,
  visible,
  whatsAppData,
  reload,
}) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const mainServiceType = [
    { id: "3", service: "Close Service" },
    { id: "1", service: "Add Service" },
    { id: "2", service: "Reschedule Srevice" },
  ];
  const [selectedMainServiceType, setSelectedMainServiceType] = React.useState(
    {}
  );

  const addServiceType = [
    { id: "1", service: "Call" },
    { id: "2", service: "Confirm" },
  ];
  const [selectedServiceType, setSelectedServiceType] = React.useState({});
  const [serviceDate, setServiceDate] = React.useState(new Date());
  const [closeServiceDate, setCloseServiceDate] = React.useState(new Date());

  const [addRemarks, setAddRemarks] = React.useState("");
  const [closeRemarks, setCloseRemarks] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [addService, setAddService] = React.useState(false);

  const [whatsAppArray, setWhatsAppArray] = React.useState(whatsAppData);
  const [selectedMessage, setSelectedMessage] = React.useState({});

  const footerContent = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        icon="pi pi-check"
        severity="success"
        onClick={() =>
          selectedMainServiceType?.id == "3"
            ? sendCloseService()
            : selectedMainServiceType?.id == "2"
            ? sendRescheduleService()
            : sendAddService()
        }
        label={
          selectedMainServiceType?.id == "3"
            ? "Close Service"
            : selectedMainServiceType?.id == "2"
            ? "Reschedule Service"
            : "Add Service"
        }
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );

  const sendRescheduleService = () => {
    if (selectedMainServiceType?.id == undefined) {
      alert("Please Select Service");
    } else if (selectedServiceType?.id == undefined) {
      alert("Please Select Service Type");
    } else if (
      moment(serviceDate).format("YYYY-MM-DD HH:mm:ss") == "Invalid date"
    ) {
      alert("Please Select Date ");
    } else {
      let data = {
        token: localStorage.getItem("token"),
        reschedule_date: moment(serviceDate).format("YYYY-MM-DD HH:mm:ss"),
        reschedule_remarks: addRemarks,
        reschedule_service_type: selectedMainServiceType?.id,
        entry_type: selectedServiceType?.id,
        customer_id: passData?.id,
        previous_date: passData?.schedule_date,
      };
      php.reschedule_service(data).then((r) => {
        if (r.error == "False") {
          setVisible(false);
          reload();
        } else {
          alert(r.message);
          if (r.error == "logout") {
            // logoutFunc();
          }
        }
      });
    }
  };

  const sendCloseService = () => {
    if (selectedMainServiceType?.id == undefined) {
      alert("Please Select Service");
    } else if (addService && selectedServiceType?.id == undefined) {
      alert("Please Select Service Type2");
    } else if (
      moment(closeServiceDate).format("YYYY-MM-DD HH:mm:ss") == "Invalid date"
    ) {
      alert("Please Select Date ");
    } else if (
      moment(serviceDate).format("YYYY-MM-DD HH:mm:ss") == "Invalid date"
    ) {
      alert("Please Select Date ");
    } else {
      let data = {
        token: localStorage.getItem("token"),
        close_date: moment(closeServiceDate).format("YYYY-MM-DD HH:mm:ss"),
        close_remarks: closeRemarks,
        entry_type: selectedMainServiceType?.id,
        // entry_type: selectedServiceType?.id,
        customer_id: passData?.id,
        amount: amount,
      };

      php.close_service(data).then((r) => {
        if (r.error == "False") {
          if (addService == true) {
            sendAddService(1);
          }
          if (selectedMessage?.id != undefined) {
            sendWAMsg();
          }
          setVisible(false);
          reload();
        } else {
          alert(r.message);
          if (r.error == "logout") {
            // logoutFunc();
          }
        }
      });
    }
  };

  const sendWAMsg = (e) => {
    let amount_2 = amount <= 0 ? 0 : amount;

    let url = `https://api.whatsapp.com/send?phone=91${passData?.mobile}`;
    let message =
      selectedMessage?.id <= 2
        ? selectedMessage?.wa_msg_1 +
          " " +
          amount_2 +
          " " +
          selectedMessage?.wa_msg_2
        : selectedMessage?.id > 2 && selectedMessage?.id > 0
        ? selectedMessage?.wa_msg_1
        : "";
    url += `&text=${encodeURI(message)}&app_absent=0`;

    window.open(url, "_blank");
  };

  const sendAddService = (close) => {
    if (selectedMainServiceType?.id == undefined) {
      alert("Please Select Service");
    } else if (selectedServiceType?.id == undefined) {
      alert("Please Select Service Type");
    } else if (
      moment(serviceDate).format("YYYY-MM-DD HH:mm:ss") == "Invalid date"
    ) {
      alert("Please Select Date ");
    } else {
      let data = {
        token: localStorage.getItem("token"),
        add_date: moment(serviceDate).format("YYYY-MM-DD HH:mm:ss"),
        add_remarks: addRemarks,
        add_service_type: close == 1 ? "1" : selectedMainServiceType?.id,
        entry_type: selectedServiceType?.id,
        customer_id: passData?.id,
      };

      php.add_service(data).then((r) => {
        if (r.error == "False") {
          setVisible(false);
          reload();
        } else {
          alert(r.message);
          if (r.error == "logout") {
            // logoutFunc();
          }
        }
      });
    }
  };

  return (
    <div>
      <Dialog
        header={
          <div style={{ color: NewTheme.MainColor }}>
            {"Services : " + passData?.name}
          </div>
        }
        visible={visible}
        position={position}
        style={{ width: "60vw" }}
        breakpoints={{ "960px": "95vw", "641px": "100vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
        draggable={false}
        resizable={false}
      >
        <BalanceContainer>
          <BalanceLabel>Type</BalanceLabel>
          <Dropdown
            style={{
              width: "50%",
              height: "40px",
            }}
            showClear
            onChange={(e) => {
              if (e.value == undefined) {
                setSelectedMainServiceType({});
              } else {
                setSelectedMainServiceType(e.value);
              }
            }}
            value={selectedMainServiceType}
            options={mainServiceType}
            optionLabel="service"
            placeholder=" Select Type"
          />
        </BalanceContainer>
        {selectedMainServiceType?.id == "1" ? (
          <AddServiceModal
            serviceDate={serviceDate}
            setServiceDate={(date) => setServiceDate(date)}
            selectedServiceType={selectedServiceType}
            setSelectedServiceType={(type) => setSelectedServiceType(type)}
            addServiceType={addServiceType}
            addRemarks={addRemarks}
            setAddRemarks={(remark) => setAddRemarks(remark)}
          />
        ) : null}
        {selectedMainServiceType?.id == "2" ? (
          <ResceduleModal
            serviceDate={serviceDate}
            setServiceDate={(date) => setServiceDate(date)}
            selectedServiceType={selectedServiceType}
            setSelectedServiceType={(type) => setSelectedServiceType(type)}
            addServiceType={addServiceType}
            addRemarks={addRemarks}
            setAddRemarks={(remark) => setAddRemarks(remark)}
          />
        ) : null}
        {selectedMainServiceType?.id == "3" ? (
          <CloseModal
            amount={amount}
            setAmount={(amt) => setAmount(amt)}
            closeServiceDate={closeServiceDate}
            setCloseServiceDate={(date) => setCloseServiceDate(date)}
            closeRemarks={closeRemarks}
            setCloseRemarks={(remark) => setCloseRemarks(remark)}
            selectedMessage={selectedMessage}
            setSelectedMessage={(msg) => setSelectedMessage(msg)}
            whatsAppArray={whatsAppArray}
            //add
            addService={addService}
            setAddService={() => setAddService(!addService)}
            serviceDate={serviceDate}
            setServiceDate={(date) => setServiceDate(date)}
            selectedServiceType={selectedServiceType}
            setSelectedServiceType={(type) => setSelectedServiceType(type)}
            addServiceType={addServiceType}
            addRemarks={addRemarks}
            setAddRemarks={(remark) => setAddRemarks(remark)}
          />
        ) : null}
      </Dialog>
    </div>
  );
}
