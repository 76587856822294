import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import Php from "../../../Backend/Php";
import {
  BalanceContainer,
  BalanceLabel,
  CreateClientButton,
} from "../../Common/Common";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

const php = new Php();
const height = window.innerHeight;

export default function EditCustomerModal({
  passData,
  setVisible,
  visible,
  position,
  reload,
}) {
  console.log(passData);
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [customerType, setCustomerType] = React.useState(false);
  const [statusType, setStatusType] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: passData?.name,
    username: passData?.username,
    password: "",
  });

  const editAdmin = (e) => {
    if (passData?.id == undefined) {
      alert("Invalid Customer. Please reload and try again!!");
    } else if (!formData?.name?.trim()) {
      alert("Enter Name Properly !!");
    } else if (!formData?.username?.trim() || formData?.username.length < 3) {
      alert("Enter Username At least 3 characters !!");
    } else if (
      formData?.password != "" &&
      (!formData?.password?.trim() || formData?.password.length < 3)
    ) {
      alert("Enter Password At least 3 characters !!");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          token: localStorage.getItem("token"),
          name: formData?.name,
          password: formData?.password,
          is_admin: customerType == true ? 1 : 0,
          status: statusType == true ? 1 : 0,
          edit_id: passData?.id,
        };
        php.edit_admin(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            reload();
          } else {
            addNotification(r.message, "error");
          }
          setVisible();
        });
      }
    }
  };

  const deleteAdmin = (e) => {
    if (passData?.id == undefined) {
      alert("Invalid Admin. Please reload and try again!!");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          token: localStorage.getItem("token"),
          id: passData?.id,
        };
        php.delete_admin(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            reload();
          } else {
            addNotification(r.message, "error");
          }
          setVisible();
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formFields = [
    {
      label: "Name",
      name: "name",
      type: "text",
      placeholder: "e.g. john david",
    },
    {
      label: "Username",
      name: "username",
      type: "text",
      placeholder: "e.g. john01",
      disabled: true,
    },
    {
      label: "Password",
      name: "password",
      type: "password",
      placeholder: "*****",
    },
  ];

  const footerContent = (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        label="Delete"
        icon="pi pi-trash"
        onClick={() => deleteAdmin()}
        className="p-button-text"
        style={{ backgroundColor: NewTheme.redcolor, color: "white" }}
      />
      <div>
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => setVisible(false)}
          className="p-button-text"
          style={{ color: NewTheme.MainColor }}
        />
        <Button
          icon="pi pi-pencil"
          severity="info"
          onClick={() => editAdmin()}
          label={"Update"}
          style={{ backgroundColor: NewTheme.MainColor }}
        />
      </div>
    </div>
  );

  return (
    <Dialog
      header={<div style={{ color: NewTheme.MainColor }}>{"Edit Admin "}</div>}
      visible={visible}
      position={position}
      style={{ width: "80vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {formFields.map((field) => (
        <BalanceContainer key={field.name}>
          <BalanceLabel>{field.label}</BalanceLabel>
          {field.type === "textarea" ? (
            <InputTextarea
              style={{ width: "50%", height: "80px" }}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          ) : (
            <InputText
              style={{
                width: "50%",
                height: "40px",
                color: field.disabled == true ? NewTheme.MainColor : "black",
                fontWeight: field.disabled == true ? "bolder" : "normal",
              }}
              type={field.type}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          )}
        </BalanceContainer>
      ))}

      <BalanceContainer>
        <BalanceLabel>{"Admin Type"}</BalanceLabel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50%",
          }}
        >
          <CreateClientButton
            active={!customerType}
            color={NewTheme.MainColor}
            onClick={() => setCustomerType(false)}
          >
            {"NO"}
          </CreateClientButton>
          <CreateClientButton
            active={customerType}
            color={NewTheme.MainColor}
            onClick={() => setCustomerType(true)}
          >
            {"YES"}
          </CreateClientButton>
        </div>
      </BalanceContainer>

      <BalanceContainer>
        <BalanceLabel>{"Status"}</BalanceLabel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            width: "50%",
          }}
        >
          <CreateClientButton
            active={!statusType}
            color={NewTheme.redcolor}
            onClick={() => setStatusType(false)}
          >
            {"INACTIVE"}
          </CreateClientButton>
          <CreateClientButton
            active={statusType}
            color={NewTheme.greencolor}
            onClick={() => setStatusType(true)}
          >
            {"ACTIVE"}
          </CreateClientButton>
        </div>
      </BalanceContainer>
    </Dialog>
  );
}
