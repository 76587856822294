import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import "./App.css";

import Notification from "./Screens/Notification/Notification";
import { NotificationProvider } from "./Screens/Notification/NotificationContext";

// Import your components for different routes
import "primeicons/primeicons.css";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Customer from "./Screens/Customers/Customer";
import Home from "./Screens/Home/Home";
import Login from "./Screens/Login/Login";
import LoginProvider from "./Screens/Login/LoginContext";
import MySidebar from "./Screens/Sidebar/MySidebar";
import { SidebarProvider } from "./Screens/Sidebar/SidebarContext";
import History from "./Screens/History/History";
import Admin from "./Screens/Admin/Admin";

const menuItems = [
  { name: "Home", component: Home },
  { name: "Customers", component: Customer },
  { name: "History", component: History },
  { name: "Admin", component: Admin },

  // Add more routes for logged-in users as needed
];

function App() {
  // React.useEffect(() => {
  //   // Check if the user is on a mobile device
  //   const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  //   console.log(isMobile);
  //   // Set initial scale based on device type
  //   const initialScale = isMobile ? "0.2" : "1.0";
  //   const initialWidth = isMobile ? "800" : "device-width";

  //   // Dynamically update the viewport meta tag
  //   const viewportMetaTag = document.querySelector('meta[name="viewport"]');
  //   if (viewportMetaTag) {
  //     viewportMetaTag.setAttribute(
  //       "content",
  //       `width=${initialWidth},initial-scale=${initialScale}, maximum-scale=1.0,minimum-scale=0.2, user-scalable=yes`
  //     );
  //   }
  // }, []);

  // localStorage.clear();
  const isLogged = localStorage.getItem("isLoggedIn") === "1";

  return (
    <div className="App">
      <SidebarProvider>
        <NotificationProvider>
          <LoginProvider>
            <PrimeReactProvider>
              <Router>
                {isLogged && <MySidebar />}

                <Routes>
                  {isLogged ? (
                    menuItems.map((menuItem) => (
                      <Route
                        key={menuItem.name}
                        path={`/${menuItem.name.toLowerCase()}`}
                        element={<menuItem.component />}
                      />
                    ))
                  ) : (
                    <Route path="login" element={<Login />} />
                  )}
                  <Route
                    path="*"
                    element={<Navigate to={isLogged ? "/Home" : "/login"} />}
                  />
                </Routes>
              </Router>
              <Notification />
            </PrimeReactProvider>
          </LoginProvider>
        </NotificationProvider>
      </SidebarProvider>
    </div>
  );
}

export default App;
