import React from "react";
import { BalanceContainer, BalanceLabel } from "../../Common/Common";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

export default function ResceduleModal({
  serviceDate,
  setServiceDate,
  setSelectedServiceType,
  selectedServiceType,
  addServiceType,
  addRemarks,
  setAddRemarks,
}) {
  return (
    <>
      <BalanceContainer>
        <BalanceLabel>Reschedule Service Date</BalanceLabel>
        <Calendar
          style={{
            width: "50%",
            height: "40px",
          }}
          hourFormat="24"
          showTime
          id="calendar-12h"
          dateFormat="dd/mm/yy"
          value={serviceDate}
          onChange={(e) => setServiceDate(e.value)}
        />
      </BalanceContainer>

      <BalanceContainer>
        <BalanceLabel>Service Type</BalanceLabel>
        <Dropdown
          style={{
            width: "50%",
            height: "40px",
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              setSelectedServiceType({});
            } else {
              setSelectedServiceType(e.value);
            }
          }}
          value={selectedServiceType}
          options={addServiceType}
          optionLabel="service"
          placeholder=" Select Service"
        />
      </BalanceContainer>

      <BalanceContainer>
        <BalanceLabel>Remarks</BalanceLabel>
        <InputTextarea
          style={{
            width: "50%",
            height: "100px",
          }}
          // autoResize
          value={addRemarks}
          variant="filled"
          onChange={(e) => setAddRemarks(e.target.value)}
          rows={5}
          cols={30}
          placeholder="Notes"
        />
      </BalanceContainer>
    </>
  );
}
