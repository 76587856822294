import React from "react";
import { Sidebar } from "primereact/sidebar";
import styled from "styled-components"; // Import styled-components for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../Assets/Images/logo.png";
import { useSidebar } from "./SidebarContext";

import {
  faHome,
  faUser,
  faSitemap,
  faCrown,
  faUserCircle,
  faChartPie,
  faChartBar,
  faHandHoldingUsd,
  faWarehouse,
  faEye,
  faUserTie,
  faGavel,
  faBell,
  faUserAlt,
  faMoneyBillTransfer,
  faMoneyBillTrendUp,
  faHistory,
} from "@fortawesome/free-solid-svg-icons";

import "./Mysidebar.css";
import { useLocation, useNavigate } from "react-router-dom";
import { NewTheme } from "../Theme/Theme";

const SidebarContainer = styled.div`
  background-color: ${NewTheme.MainColor};
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 1rem;
`;

const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0;
  border: 1px solid transparent;
  transition: border 0.3s, transform 0.3s, color 0.3s, background-color 0.3s,
    border-radius 0.3s;
  background-color: transparent;
  box-sizing: border-box;
  margin-top: 5px;
  svg {
    color: ${NewTheme.lightmain};
    margin-right: 0.5rem;
    transition: color 0.3s;
    padding-left: 10px;
  }

  &:hover {
    border: 1px solid ${NewTheme.MainColor};
    background-color: white;
    color: ${NewTheme.MainColor};
    border-radius: 4px;

    svg {
      color: ${NewTheme.MainColor};
    }
  }

  &.active {
    border: 1px solid ${NewTheme.MainColor};
    background-color: white;
    color: ${NewTheme.MainColor};
    border-radius: 4px;

    svg {
      color: ${NewTheme.MainColor};
    }
  }
`;

const MySidebar = () => {
  const { isOpen, toggleSidebar } = useSidebar();

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const customHeader = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ width: 60, height: 60 }}>
        <img
          src={
            "https://service.postick.co.in/Apis/Images/" +
            JSON.parse(localStorage.getItem("admin_data"))?.admin_id +
            ".png?" +
            new Date()
          }
          style={{ width: 60, height: 60 }}
        />
      </div>
      <div
        style={{
          marginLeft: 10,
          fontWeight: "bold",
          color: NewTheme.MainColor,
          fontSize: 20,
        }}
      >
        {JSON.parse(
          localStorage.getItem("admin_data")
        )?.white_label.toUpperCase()}
        <br />
        <span style={{ fontSize: 16, color: "black", marginTop: -3 }}>
          {JSON.parse(localStorage.getItem("admin_data"))?.name.toUpperCase()}
        </span>
      </div>
    </div>
  );

  const customIcons = (
    <React.Fragment>
      <button
        onClick={() => logoutFunc()}
        className="p-sidebar-icon p-link mr-2"
      >
        <span
          className="pi pi-power-off"
          style={{ color: "red", fontWeight: "bolder" }}
        />
      </button>
    </React.Fragment>
  );
  const adminData = JSON.parse(localStorage.getItem("admin_data"));
  const menuItems = [
    { name: "Home", icon: faHome, route: "/Home" },
    { name: "Customers", icon: faUser, route: "/Customers" },
    { name: "History", icon: faHistory, route: "/History" },
    adminData?.is_admin == 1
      ? { name: "Admin", icon: faCrown, route: "/Admin" }
      : null,
  ].filter((item) => item !== null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleMenuItemClick = (item) => {
    navigate(item.route);
    toggleSidebar();
  };

  return (
    <Sidebar
      visible={isOpen}
      onHide={toggleSidebar}
      style={{ padding: 0, margin: 0 }}
      closeOnEscape={true}
      icons={customIcons}
      header={customHeader}
    >
      <SidebarContainer>
        {menuItems.map((item, index) => (
          <SidebarItem
            key={index}
            onClick={() => handleMenuItemClick(item)}
            className={location.pathname === item.route ? "active" : ""}
          >
            <FontAwesomeIcon icon={item.icon} size="lg" />
            <span>{item.name}</span>
          </SidebarItem>
        ))}
        {/* Add more items as needed */}
      </SidebarContainer>
    </Sidebar>
  );
};

export default MySidebar;
