import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { NewTheme } from "../Theme/Theme";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import Php from "../../Backend/Php";
import DOMPurify from "dompurify";
import { InputText } from "primereact/inputtext";
import { useWindowSize } from "@react-hook/window-size";
import ServiceModal from "./Modal/ServiceModal";
import * as ExcelJS from "exceljs";

const php = new Php();

export default function HomeTable() {
  const [transactionData, setTransactionData] = useState([]);
  const [whatsAppData, setWhatsAppData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [width, height] = useWindowSize();

  const [schdulemodel, setSchdulemodel] = useState(false);
  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    loadCustomer();
  }, []);

  const loadCustomer = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      fromdate: moment().format("YYYY-MM-DD 00:00:00"),
      todate: moment().format("YYYY-MM-DD 23:59:59"),
    };

    php.load_customer(data).then((r) => {
      if (r.error == "False") {
        setTransactionData(r.data);
        setWhatsAppData(r.wa_msg);
        // setAmount(r.amount)
        // SetServices(r.services)
        // SetReschedule(r.reschedule)
        // setCall(r.call)
        // SetConfirm(r.confirm)
        // setDashboardData(r.dashboard);
        // setMarquee(r.marquee);
      } else {
        alert(r.message);
        if (r.error == "logout") {
          // logoutFunc();
        }
      }
      setLoading(false);
    });
  };

  const [visibleServiceModal, setVisibleServiceModal] = useState(false);
  const [position, setPosition] = useState("top");
  const [passData, setPassData] = useState([]);

  const showServiceModal = (data) => {
    setPassData(data);
    setVisibleServiceModal(true);
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "nos",
      "name",
      "address",
      "mobile",
      "city",
      "area",
      "service_type2",
      "new_old",
      "schedule_date",
      "remarks",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Nos",
      "Name",
      "Address",
      "Mobile",
      "City",
      "Area",
      "Service",
      "Status",
      "Schedule_date",
      "Remarks",
    ];

    const headerRow = worksheet
      .addRow([
        "SERVICES " +
          JSON.parse(
            localStorage.getItem("admin_data")
          )?.white_label.toUpperCase(),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from transactionData
    const filteredData = transactionData.map((item) => {
      return colsToExport.reduce((acc, col) => {
        if (col === "mobile") {
          // Replace <br/> with a comma in the mobile field
          acc[col] = item[col]
            .replace(/<div>|<\/div>|<br\s*\/?>/g, ",")
            .replace(/^,|,$/g, "");
        } else if (col === "new_old") {
          // Check the status and replace with "NEW" or "OLD"
          acc[col] = item[col] == 1 ? "NEW" : "OLD";
        } else {
          acc[col] = item[col];
        }
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      const row = worksheet.addRow(Object.values(data));

      // Apply conditional formatting to the schedule_date cell
      const scheduleDate = data.schedule_date;
      const cell = row.getCell(colsToExport.indexOf("schedule_date") + 1);

      let fillColor;
      if (!scheduleDate) {
        fillColor = "FFC107"; // warning (yellow)
      } else if (moment(scheduleDate).isBefore(moment())) {
        fillColor = "DC3545"; // danger (red)
      } else {
        fillColor = "28A745"; // success (green)
      }

      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: fillColor },
      };
      cell.font = {
        bold: true,
        color: { argb: "FFFFFFFF" }, // white
      };
    });
    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    var fileName =
      "SERVICES " +
      JSON.parse(localStorage.getItem("admin_data"))?.white_label.toUpperCase();

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  return (
    <>
      <div
        style={{
          height: 40,
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          paddingRight: 20,
          fontWeight: "bold",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
          }}
        >
          <Button
            type="button"
            label="XLS"
            severity="success"
            style={{
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
            }}
            onClick={exportExcel}
            data-pr-tooltip="EXCEL"
          />
        </div>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <InputText
            style={{ width: 200 }}
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Search"
          />
        </div>
      </div>
      <div id={"stick"} style={{ position: "relative", width: width }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 160}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={[
            "name",
            "address",
            "mobile",
            "nos",
            "city",
            "service_type2",
            "area",
            "schedule_date",
          ]}
          paginator
          rows={15}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
          // resizableColumns
        >
          <Column
            field="nos"
            showFilterMenu={false}
            header="#"
            sortable
            filter
          />
          <Column
            field="name"
            showFilterMenu={false}
            header="Name"
            sortable
            filter
          />
          <Column
            filter
            field="qty"
            showFilterMenu={false}
            header="Address"
            body={(rowData) => (
              <div
                style={{
                  fontSize: 12,
                }}
              >
                {rowData.address}
              </div>
            )}
            sortable
          />
          <Column
            filter
            field="mobile"
            showFilterMenu={false}
            header="Mobile"
            body={(rowData) => (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(rowData.mobile),
                }}
              />
            )}
            sortable
          />
          <Column
            sortable
            filter
            field="city"
            showFilterMenu={false}
            header="City"
          />
          <Column
            sortable
            field="area"
            filter
            showFilterMenu={false}
            header="Area"
          />
          <Column
            field="service_type2"
            filter
            sortable
            showFilterMenu={false}
            header="Service"
          />
          <Column
            filter
            field="status"
            showFilterMenu={false}
            header="Status"
            sortable
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.new_old == 1
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.new_old == 0 ? "OLD" : "NEW"}
              </div>
            )}
          />

          <Column
            field="schedule_date"
            sortable
            body={(rowData) => (
              <div style={{ width: 120 }}>
                <Button
                  type="button"
                  severity={
                    rowData.schedule_date == null
                      ? "warning"
                      : moment(rowData.schedule_date) < moment()
                      ? "danger"
                      : "success"
                  }
                  style={{
                    fontSize: 14,
                    width: "100%",
                    padding: 5,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  onClick={() => showServiceModal(rowData)}
                  data-pr-tooltip="PDF"
                >
                  {moment(rowData.schedule_date).format("DD-MMM-YY")}
                </Button>
              </div>
            )}
            showFilterMenu={false}
            header="Schedule"
          />

          <Column
            filter
            field="remarks"
            showFilterMenu={false}
            header="Remarks"
          />
        </DataTable>
      </div>
      {visibleServiceModal && (
        <ServiceModal
          whatsAppData={whatsAppData}
          key={new Date()}
          setVisible={() => setVisibleServiceModal(false)}
          position={position}
          visible={visibleServiceModal}
          passData={passData}
          reload={() => loadCustomer()}
        />
      )}
    </>
  );
}
