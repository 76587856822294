import React from "react";
import styled from "styled-components";
import TopbarMost from "../Common/TopbarMost";
import { NewTheme } from "../Theme/Theme";
import AdminTable from "./AdminTable";
import AddAdminModal from "./Modal/AddAdminModal";
import ChangePassword from "./Modal/ChangePassword";

const CreateClientButton = styled.button`
  background-color: ${(props) =>
    props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
  color: ${(props) => (props.greencolor ? "#ffffff" : NewTheme.lightmain)};
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s; /* Add smooth transitions */

  &:hover {
    background-color: #ffffff;
    color: ${(props) =>
      props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
    border: 1px solid
      ${(props) =>
        props.greencolor ? NewTheme.greencolor : NewTheme.MainColor};
  }
`;

export default function Admin() {
  const [refresh, setRefresh] = React.useState(false);

  const [visibleAddModal, setVisibleAddModal] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const [visiblePasswordModal, setVisiblePasswordModal] = React.useState(false);

  const showPasswordModal = (data) => {
    setVisiblePasswordModal(true);
  };

  const showAddModal = () => {
    setVisibleAddModal(true);
  };

  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <CreateClientButton
          style={{
            height: 30,
            marginRight: 30,
          }}
          onClick={() => showPasswordModal()}
        >
          Change Password
        </CreateClientButton>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <CreateClientButton
          greencolor
          style={{
            height: 30,
            marginRight: 30,
          }}
          onClick={() => showAddModal()}
        >
          + ADD ADMIN
        </CreateClientButton>
      </div>
    </div>
  );

  return (
    <div>
      <TopbarMost name={" Admin "} cmp={topworkui} />
      <AdminTable refresh={refresh} />
      {visibleAddModal && (
        <AddAdminModal
          key={new Date()}
          setVisible={() => setVisibleAddModal(false)}
          position={position}
          visible={visibleAddModal}
          reload={() => setRefresh(!refresh)}
        />
      )}
      {visiblePasswordModal && (
        <ChangePassword
          key={new Date()}
          setVisible={() => {
            setVisiblePasswordModal(false);
          }}
          position={position}
          visible={visiblePasswordModal}
          reload={() => {
            localStorage.clear();
            window.location = "/Login";
          }}
        />
      )}
    </div>
  );
}
