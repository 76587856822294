import React from "react";
import { BalanceContainer, BalanceLabel } from "../../Common/Common";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { NewTheme } from "../../Theme/Theme";
import styled, { css } from "styled-components";

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background 0.3s ease;
  width: 80%;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export default function CloseModal({
  closeServiceDate,
  setCloseServiceDate,
  amount,
  setAmount,
  closeRemarks,
  setCloseRemarks,
  selectedMessage,
  setSelectedMessage,
  whatsAppArray,
  //add
  addService,
  setAddService,
  serviceDate,
  setServiceDate,
  setSelectedServiceType,
  selectedServiceType,
  addServiceType,
  addRemarks,
  setAddRemarks,
}) {
  return (
    <>
      <BalanceContainer>
        <BalanceLabel>Close Service Date</BalanceLabel>
        <Calendar
          style={{
            width: "50%",
            height: "40px",
          }}
          hourFormat="24"
          showTime
          id="calendar-12h"
          dateFormat="dd/mm/yy"
          value={closeServiceDate}
          onChange={(e) => setCloseServiceDate(e.value)}
        />
      </BalanceContainer>

      <BalanceContainer>
        <BalanceLabel>Amount</BalanceLabel>
        <InputText
          style={{
            width: "50%",
            height: "40px",
          }}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Payment"
        />
      </BalanceContainer>
      <BalanceContainer>
        <BalanceLabel>Remarks</BalanceLabel>
        <InputTextarea
          style={{
            width: "50%",
            height: "100px",
          }}
          // autoResize
          value={closeRemarks}
          variant="filled"
          onChange={(e) => setCloseRemarks(e.target.value)}
          rows={5}
          cols={30}
          placeholder="Notes"
        />
      </BalanceContainer>

      <BalanceContainer>
        <BalanceLabel>Select Message</BalanceLabel>
        <Dropdown
          style={{
            width: "50%",
            height: "40px",
          }}
          showClear
          onChange={(e) => {
            if (e.value == undefined) {
              setSelectedMessage({});
            } else {
              setSelectedMessage(e.value);
            }
          }}
          value={selectedMessage}
          options={whatsAppArray}
          optionLabel="wa_name"
          placeholder=" Select Tyype"
        />
      </BalanceContainer>
      <BalanceContainer>
        <BalanceLabel>WA Message</BalanceLabel>
        <InputTextarea
          style={{
            width: "50%",
            height: "180px",
          }}
          value={
            selectedMessage?.id <= 2
              ? selectedMessage?.wa_msg_1 +
                " " +
                amount +
                " " +
                selectedMessage?.wa_msg_2
              : selectedMessage?.id > 2 && selectedMessage?.id > 0
              ? selectedMessage?.wa_msg_1
              : ""
          }
          variant="filled"
          rows={5}
          cols={30}
          placeholder="Select Message"
        />
      </BalanceContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <CreateClientButton
          active={addService}
          color={NewTheme.MainColor}
          onClick={() => setAddService()}
        >
          {"Add Service"}
        </CreateClientButton>
      </div>
      {addService && (
        <>
          <BalanceContainer>
            <BalanceLabel>Set Service Date</BalanceLabel>
            <Calendar
              style={{
                width: "50%",
                height: "40px",
              }}
              hourFormat="24"
              showTime
              id="calendar-12h"
              dateFormat="dd/mm/yy"
              value={serviceDate}
              onChange={(e) => setServiceDate(e.value)}
            />
          </BalanceContainer>

          <BalanceContainer>
            <BalanceLabel>Service Type</BalanceLabel>
            <Dropdown
              style={{
                width: "50%",
                height: "40px",
              }}
              showClear
              onChange={(e) => {
                if (e.value == undefined) {
                  setSelectedServiceType({});
                } else {
                  setSelectedServiceType(e.value);
                }
              }}
              value={selectedServiceType}
              options={addServiceType}
              optionLabel="service"
              placeholder=" Select Service"
            />
          </BalanceContainer>

          <BalanceContainer>
            <BalanceLabel>Remarks</BalanceLabel>
            <InputTextarea
              style={{
                width: "50%",
                height: "100px",
              }}
              // autoResize
              value={addRemarks}
              variant="filled"
              onChange={(e) => setAddRemarks(e.target.value)}
              rows={5}
              cols={30}
              placeholder="Notes"
            />
          </BalanceContainer>
        </>
      )}
    </>
  );
}
