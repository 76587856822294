import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { NewTheme } from "../Theme/Theme";
import moment from "moment";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import Php from "../../Backend/Php";
import DOMPurify from "dompurify";
import { InputText } from "primereact/inputtext";
import { useWindowSize } from "@react-hook/window-size";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import wa from "./Images/whatsapp.svg";
import edit from "../Customers/Images/edit.svg";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import EditCustomerModal from "./Modal/EditAdminModal";
// import HistoryModal from "./Modal/HistoryModal";
// import ServiceModal from "../Home/Modal/ServiceModal";
// import WhatsAppModal from "./Modal/WhatsAppModal";
// import EditCustomerModal from "./Modal/EditCustomerModal";
// import ServiceModal from "./Modal/ServiceModal";

const php = new Php();

export default function AdminTable({ refresh }) {
  const [transactionData, setTransactionData] = useState([]);
  const [whatsAppData, setWhatsAppData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [width, height] = useWindowSize();

  const [schdulemodel, setSchdulemodel] = useState(false);
  const [filters, setFilters] = useState({
    symbol: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  });

  const [filtersGlobal, setFiltersGlobal] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filtersGlobal["global"] ? filtersGlobal["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filtersGlobal };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  useEffect(() => {
    loadAdmin();
  }, [refresh]);

  const loadAdmin = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      fromdate: moment().format("YYYY-MM-DD 00:00:00"),
      todate: moment().format("YYYY-MM-DD 23:59:59"),
    };

    php.load_admin(data).then((r) => {
      if (r.error == "False") {
        setTransactionData(r.data);
        setWhatsAppData(r.wa_msg);
      } else {
        alert(r.message);
        if (r.error == "logout") {
          // logoutFunc();
        }
      }
      setLoading(false);
    });
  };

  const [visibleEditAppModal, setVisibleEditAppModal] = useState(false);
  const [position, setPosition] = useState("top");
  const [passDataEdit, setPassDataEdit] = useState([]);

  const showEditModal = (data) => {
    setPassDataEdit(data);
    setVisibleEditAppModal(true);
  };

  return (
    <>
      <div style={{ height: 40 }}>
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <InputText
            style={{ width: 200 }}
            type="search"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
            placeholder="Search"
          />
        </div>
      </div>
      <div id={"stick"} style={{ position: "relative", width: width }}>
        <DataTable
          removableSort
          stripedRows
          showGridlines
          scrollable
          scrollHeight={height - 160}
          size="small"
          // filterDisplay="row"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          value={transactionData}
          style={{ fontWeight: "bold", fontSize: 14 }}
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          globalFilterFields={["name", "address", "mobile", "nos"]}
          paginator
          rows={15}
          loading={loading}
          filters={filters}
          onFilter={(e) => setFilters(e.filters)}
          rowsPerPageOptions={[10, 15, 30, 50, 100]}
          // resizableColumns
        >
          <Column
            field="nos"
            showFilterMenu={false}
            header="#"
            sortable
            filter
          />
          <Column
            field="name"
            showFilterMenu={false}
            header="Name"
            sortable
            filter
            body={(rowData) => <div>{rowData.name}</div>}
          />

          <Column
            field="username"
            showFilterMenu={false}
            header="Username"
            sortable
            filter
            body={(rowData) => <div>{rowData.username}</div>}
          />

          <Column
            field="password"
            showFilterMenu={false}
            header="Password"
            sortable
            filter
            body={(rowData) => <div>{rowData.password}</div>}
          />

          <Column
            filter
            field="status"
            showFilterMenu={false}
            header="Status"
            sortable
            body={(rowData) => (
              <div
                style={{
                  color:
                    rowData.status == 1
                      ? NewTheme.greencolor
                      : NewTheme.redcolor,
                }}
              >
                {rowData.status == 0 ? "Inactive" : "Active"}
              </div>
            )}
          />
          <Column
            field="date_created"
            style={{ wordWrap: "break-word" }}
            showFilterMenu={false}
            header="Date Created"
            body={(rowData) => (
              <div>
                {moment(rowData.date_created).format("DD-MMM-YY ")}
                <span style={{ color: "gray" }}>
                  {moment(rowData.date_created).format("HH:mm:ss")}
                </span>
              </div>
            )}
            sortable
          />
          <Column
            field=""
            body={(rowData) => (
              <div style={{ width: 100 }}>
                <Button
                  type="button"
                  icon={
                    <img
                      src={edit}
                      style={{ height: 15, width: 15, color: "white" }}
                    />
                  }
                  severity="warning"
                  style={{
                    height: 25,
                    width: 25,
                    // backgroundColor: "#ffc107",
                    marginRight: 10,
                  }}
                  onClick={() => showEditModal(rowData)}
                  data-pr-tooltip="PDF"
                />
              </div>
            )}
            showFilterMenu={false}
            header="Operate"
          />
        </DataTable>
      </div>

      {visibleEditAppModal && (
        <EditCustomerModal
          key={new Date()}
          setVisible={() => {
            setVisibleEditAppModal(false);
            setPassDataEdit([]);
          }}
          position={position}
          visible={visibleEditAppModal}
          passData={passDataEdit}
          reload={() => loadAdmin()}
        />
      )}
    </>
  );
}
