import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useNotification } from "../../Notification/NotificationContext";
import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { NewTheme } from "../../Theme/Theme";
import Php from "../../../Backend/Php";

const php = new Php();
const height = window.innerHeight;

export default function HistoryModal({
  passData,
  setVisible,
  visible,
  position,
}) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [transactionData, setTransactionData] = React.useState([]);
  const [transactionDeleteData, setTransactionDeleteData] = React.useState([]);

  const [totalProfitLoss, setProfitLoss] = React.useState(0);
  const [totalBrokerage, setTotalBrokerage] = React.useState(0);
  const [totalVolume, setTotalVolume] = React.useState(0);

  React.useEffect(() => {
    get_user_history();
  }, []);

  const get_user_history = (e) => {
    if (!loading) {
      setLoading(true);
      let data = {
        token: localStorage.getItem("token"),
        id: passData?.id,
      };

      php.get_user_history(data).then((r) => {
        setLoading(false);
        if (r.error === "False") {
          setTransactionData(r.data);
        } else {
          addNotification(r.message, "error");
        }
      });
    }
  };

  const delete_entry = (e) => {
    if (e > 0) {
      if (window.confirm("Do you really want to delete this service?")) {
        if (!loading) {
          setLoading(true);
          let data = {
            token: localStorage.getItem("token"),
            id: e,
          };

          php.delete_entry(data).then((r) => {
            setLoading(false);
            if (r.error === "False") {
              get_user_history();
            } else {
              addNotification(r.message, "error");
            }
          });
        }
      }
    } else {
      addNotification("Invalid service id !!", "error");
    }
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Services History : " + passData?.name}
        </div>
      }
      visible={visible}
      position={position}
      style={{ width: "100vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <DataTable
        removableSort
        stripedRows
        showGridlines
        scrollable
        scrollHeight={height - 120}
        size="small"
        // filters={filters}
        // filterDisplay="row"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        value={!loading && transactionData}
        style={{ fontWeight: "bold", fontSize: 14 }}
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        globalFilterFields={["username", "symbol"]}
        paginator
        rows={15}
        rowsPerPageOptions={[10, 15, 30, 50, 100]}
      >
        <Column field="id" showFilterMenu={false} header="SR" sortable />
        <Column
          field="admin"
          showFilterMenu={false}
          header="Admin"
          sortable
          filter
          body={(rowData) => (
            <div
              style={{
                color: NewTheme.MainColor,
              }}
            >
              {rowData.admin}
            </div>
          )}
        />
        <Column
          field="service_type"
          showFilterMenu={false}
          header="Service"
          body={(rowData) => <div>{rowData.service_type2}</div>}
        />

        <Column
          field="previous_date"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header="Previous Date"
          body={(rowData) => (
            <div>
              {rowData.previous_date == "N/A"
                ? rowData.previous_date
                : moment(rowData.previous_date).format("DD-MMM-YY HH:mm:ss")}
            </div>
          )}
          sortable
        />

        <Column
          field="service_date"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header="Service Date"
          body={(rowData) => (
            <div>
              {rowData.service_date == "N/A"
                ? rowData.service_date
                : moment(rowData.service_date).format("DD-MMM-YY HH:mm:ss")}
            </div>
          )}
          sortable
        />

        <Column
          field="type"
          sortable
          showFilterMenu={false}
          header="Type"
          body={(rowData) => (
            <div
              style={{
                color:
                  rowData.type2 == "0"
                    ? "red"
                    : rowData.type2 == "1"
                    ? "green"
                    : "orange",
              }}
            >
              {rowData.type2}
            </div>
          )}
        />

        <Column
          field="amount"
          sortable
          showFilterMenu={false}
          header="Amount"
          body={(rowData) => <div>{rowData.amount}</div>}
        />
        <Column
          field="remarks"
          showFilterMenu={false}
          header="Remarks"
          style={{ width: "20%" }}
          body={(rowData) => <div>{rowData.remarks}</div>}
        />

        <Column
          field="date_created"
          style={{ wordWrap: "break-word" }}
          showFilterMenu={false}
          header="Date Created"
          body={(rowData) => (
            <div>
              {moment(rowData.date_created).format("DD-MMM-YY HH:mm:ss")}
            </div>
          )}
          sortable
        />

        <Column
          field=""
          body={(rowData) => (
            <div style={{ width: 100 }}>
              <Button
                type="button"
                icon={"pi pi-trash"}
                severity="danger"
                onClick={() => delete_entry(rowData.id)}
                style={{ height: 25, width: 25 }}
              />
            </div>
          )}
          showFilterMenu={false}
          header="Operate"
        />
      </DataTable>
    </Dialog>
  );
}
