import React from "react";

import TopbarMost from "../Common/TopbarMost";
import { NewTheme } from "../Theme/Theme";

import Php from "../../Backend/Php";

import moment from "moment";
import HomeTable from "./HomeTable";

const php = new Php();
const height = window.innerHeight;
const totalHeight = (height / 100) * 95;

export default function Home() {
  const [dashboardData, setDashboardData] = React.useState([]);

  const initialShowNotification =
    JSON.parse(localStorage.getItem("showNotification")) || false;
  const [showNotification, setShowNotification] = React.useState(
    initialShowNotification
  );

  const [amount, setAmount] = React.useState(0);
  const [call, setCall] = React.useState(0);
  const [confirm, SetConfirm] = React.useState(0);
  const [reschedule, SetReschedule] = React.useState(0);
  const [services, SetServices] = React.useState(0);

  React.useEffect(() => {
    // Save showNotification to localStorage whenever it changes
    localStorage.setItem("showNotification", JSON.stringify(showNotification));
  }, [showNotification]);

  React.useEffect(() => {
    loadDashboard();
  }, []);

  const loadDashboard = () => {
    let data = {
      token: localStorage.getItem("token"),
      fromdate: moment().format("YYYY-MM-DD 00:00:00"),
      todate: moment().format("YYYY-MM-DD 23:59:59"),
    };

    php.home_api(data).then((r) => {
      if (r.error == "False") {
        setAmount(r.amount);
        SetServices(r.services);
        SetReschedule(r.reschedule);
        setCall(r.call);
        SetConfirm(r.confirm);
        // setDashboardData(r.dashboard);
        // setMarquee(r.marquee);
      } else {
        alert(r.message);
        if (r.error == "logout") {
          logoutFunc();
        }
      }
    });
  };

  const logoutFunc = () => {
    localStorage.clear();
    window.location = "/";
  };

  const topworkui = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: 20,
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Services</div>
        <div style={{ fontSize: 18, fontWeight: "bold" }}>{services}</div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Confimed</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          {confirm}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Call & Confirm</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: "orange",
          }}
        >
          {call}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Rescedule</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          {reschedule}
        </div>
      </div>
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: 30,
          flexDirection: "column",
        }}
      >
        <div style={{ fontSize: 12 }}>Collection</div>
        <div
          style={{
            fontSize: 18,
            fontWeight: "bold",
            color: NewTheme.greencolor,
          }}
        >
          {amount}
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <TopbarMost
        name={
          JSON.parse(
            localStorage.getItem("admin_data")
          )?.white_label.toUpperCase() + " | HOME "
        }
        cmp={topworkui}
      />
      <HomeTable />
    </div>
  );
}
