import React from "react";
import logo from "../../Assets/Images/logo.jpg";
import { useUserContext } from "./LoginContext";
import { Input, LoginContainer, LoginForm, Logo, SmallButton } from "./styles";
import { BrowserView } from "react-device-detect";

function LoginPage() {
  const { loading, handleInputs, handleLogin } = useUserContext() ?? {};

  // Add event listener to detect Enter key press
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  };

  return (
    <LoginContainer>
      <LoginForm onKeyDown={handleKeyDown}>
        <Logo src={logo} alt="Logo" />

        <Input
          type="text"
          id="username"
          placeholder="Username"
          // value={username}
          onChange={handleInputs}
        />
        <Input
          type="password"
          id="password"
          placeholder="Password"
          // value={password}
          onChange={handleInputs}
        />
        <SmallButton onClick={loading ? null : handleLogin} type="button">
          {loading ? "loading.." : "Login"}
        </SmallButton>
      </LoginForm>
    </LoginContainer>
  );
}

export default LoginPage;
