import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { BalanceContainer, BalanceLabel } from "../../Common/Common";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import wa from "../Images/whatsapp.svg";

const height = window.innerHeight;

export default function WhatsAppModal({
  passData,
  setVisible,
  visible,
  position,
  whatsAppData,
}) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState(passData?.name);
  const [mobile, setMobile] = React.useState(passData?.mobile);
  const [amount, setAmount] = React.useState(0);

  const [selectedMessage, setSelectedMessage] = React.useState({});

  const sendWAMsg = (e) => {
    let amount_2 = amount <= 0 ? 0 : amount;

    let url = `https://api.whatsapp.com/send?phone=91${mobile}`;
    let message =
      selectedMessage?.id <= 2
        ? selectedMessage?.wa_msg_1 +
          " " +
          amount_2 +
          " " +
          selectedMessage?.wa_msg_2
        : selectedMessage?.id > 2 && selectedMessage?.id > 0
        ? selectedMessage?.wa_msg_1
        : "";
    url += `&text=${encodeURI(message)}&app_absent=0`;

    window.open(url, "_blank");
  };

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        label="Send "
        icon={
          <img
            src={wa}
            style={{
              height: 20,
              width: 20,
              color: "white",
              marginRight: 5,
            }}
          />
        }
        onClick={() => sendWAMsg()}
        className="p-button-text"
        style={{ backgroundColor: "#28a745", color: "white" }}
      />
    </div>
  );
  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Send WhatsApp Message : " + passData?.name}
        </div>
      }
      visible={visible}
      position={position}
      style={{ width: "100vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      <>
        <BalanceContainer>
          <BalanceLabel>Name</BalanceLabel>
          <InputText
            style={{
              width: "50%",
              height: "40px",
            }}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </BalanceContainer>
        <BalanceContainer>
          <BalanceLabel>Mobile</BalanceLabel>
          <InputText
            type="number"
            style={{
              width: "50%",
              height: "40px",
            }}
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </BalanceContainer>
        <BalanceContainer>
          <BalanceLabel>Amount</BalanceLabel>
          <InputText
            type="number"
            style={{
              width: "50%",
              height: "40px",
            }}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </BalanceContainer>

        <BalanceContainer>
          <BalanceLabel>Select Message</BalanceLabel>
          <Dropdown
            style={{
              width: "50%",
              height: "40px",
            }}
            showClear
            onChange={(e) => {
              if (e.value == undefined) {
                setSelectedMessage({});
              } else {
                setSelectedMessage(e.value);
              }
            }}
            value={selectedMessage}
            options={whatsAppData}
            optionLabel="wa_name"
            placeholder=" Select Tyype"
          />
        </BalanceContainer>

        <BalanceContainer>
          <BalanceLabel>WA Message</BalanceLabel>
          <InputTextarea
            style={{
              width: "50%",
              height: "180px",
            }}
            value={
              selectedMessage?.id <= 2
                ? selectedMessage?.wa_msg_1 +
                  " " +
                  amount +
                  " " +
                  selectedMessage?.wa_msg_2
                : selectedMessage?.id > 2 && selectedMessage?.id > 0
                ? selectedMessage?.wa_msg_1
                : ""
            }
            variant="filled"
            rows={5}
            cols={30}
            placeholder="Select Message"
          />
        </BalanceContainer>
      </>
    </Dialog>
  );
}
