import styled, { css } from "styled-components";
import { NewTheme } from "../Theme/Theme";

const RightPanelContainer = styled.div`
  flex: 3;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-left: 0px solid black; /* Add a 1px black border on the left side */
`;

export const BalanceWrapper = styled.div`
  /* border-bottom: 1px solid lightgray; */
  /* margin-bottom: 20px; */
  display: flex; /* Make child elements horizontal */
  flex-direction: column;
  width: 90%;
  justify-content: space-between; /* Add space between children */
`;

export const BalanceContainer = styled.div`
  margin-bottom: 20px;
  display: flex; /* Make child elements horizontal */
  align-items: center; /* Vertically center align child elements */
  justify-content: space-between; /* Add space between children */
`;

export const BalanceInput = styled.input`
  background-color: white;
  color: rgb(0, 0, 0);
  transition: all 0.2s ease 0s;
  height: 40px;
  border-radius: 5px;
  width: 50%;
  border: 0.5px solid lightgray;
  padding-left: 10px;
  ${(props) =>
    props.disabled &&
    css`
      background-color: #edebfa;
      color: ${NewTheme.MainColor};
      font-weight: bold;
    `}
`;

export const CreateClientButton = styled.button`
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  transition: background 0.3s ease;

  ${(props) =>
    props.active
      ? css`
          border: 1px solid ${props.color ? props.color : "#3959ab"};
          background-color: ${props.color ? props.color : "#3959ab"};
          color: #fff;
        `
      : css`
          background: none;
          color: ${props.color ? props.color : "#3959ab"};
          border: 1px solid ${props.color ? props.color : "#3959ab"};
        `}
`;

export const BalanceUpdateButton = styled.button`
  display: flex; /* Center content horizontally and vertically */
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 150px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  color: white;
  padding: 14px 28px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 0px;
  background-color: ${NewTheme.MainColor};
  border-color: ${NewTheme.MainColor};
  align-self: center;

  &:focus-visible {
    border-color: #6366f1;
    color: #ffffff;
    border-width: 3px;
    border: 1px solid #ffffff;
  }
`;

export const BalanceLabel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
  padding-right: 10px;
  color: gray;
  text-align: left;
`;

export const BalanceAmountText = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 20px;
  color: gray;
`;
