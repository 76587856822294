import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import Php from "../../../Backend/Php";
import {
  BalanceContainer,
  BalanceLabel,
  CreateClientButton,
} from "../../Common/Common";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";

const php = new Php();
const height = window.innerHeight;

export default function ChangePassword({
  passData,
  setVisible,
  visible,
  position,
  reload,
}) {
  const { addNotification } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const [formData, setFormData] = React.useState({
    old_password: "",
    new_password: "",
  });

  const editAdmin = (e) => {
    if (!formData?.new_password?.trim() || formData?.new_password.length < 3) {
      alert("Enter New Password At least 3 characters !!");
    } else if (
      !formData?.old_password?.trim() ||
      formData?.old_password.length < 3
    ) {
      alert("Please Enter Old Password !!");
    } else {
      if (!loading) {
        setLoading(true);
        let data = {
          token: localStorage.getItem("token"),
          old_password: formData?.old_password,
          new_password: formData?.new_password,
        };

        php.edit_password_admin(data).then((r) => {
          setLoading(false);
          if (r.error === "False") {
            reload();
          } else {
            addNotification(r.message, "error");
          }
          setVisible();
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const formFields = [
    {
      label: "Old Password",
      name: "old_password",
      type: "text",
      placeholder: "*****",
    },
    {
      label: "New Password",
      name: "new_password",
      type: "text",
      placeholder: "*****",
    },
  ];

  const footerContent = (
    <div>
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setVisible(false)}
        className="p-button-text"
        style={{ color: NewTheme.MainColor }}
      />
      <Button
        icon="pi pi-pencil"
        severity="info"
        onClick={() => editAdmin()}
        label={"Update"}
        style={{ backgroundColor: NewTheme.MainColor }}
      />
    </div>
  );

  return (
    <Dialog
      header={
        <div style={{ color: NewTheme.MainColor }}>
          {"Change Admin Password"}
        </div>
      }
      visible={visible}
      position={position}
      style={{ width: "80vw" }}
      breakpoints={{ "960px": "95vw", "641px": "100vw" }}
      onHide={() => setVisible(false)}
      footer={footerContent}
      draggable={false}
      resizable={false}
    >
      {formFields.map((field) => (
        <BalanceContainer key={field.name}>
          <BalanceLabel>{field.label}</BalanceLabel>
          {field.type === "textarea" ? (
            <InputTextarea
              style={{ width: "50%", height: "80px" }}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          ) : (
            <InputText
              style={{
                width: "50%",
                height: "40px",
                color: field.disabled == true ? NewTheme.MainColor : "black",
                fontWeight: field.disabled == true ? "bolder" : "normal",
              }}
              type={field.type}
              value={formData[field.name]}
              onChange={handleChange}
              name={field.name}
              placeholder={field.placeholder}
              disabled={field.disabled}
            />
          )}
        </BalanceContainer>
      ))}
    </Dialog>
  );
}
