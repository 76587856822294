const api_link = "https://service.postick.co.in/Apis/unnati_api.php";
const access_key = "Unnati@123";

export default class Php {
  login(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        login: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_password_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        edit_password_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        edit_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_customer: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  home_api(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        home_api: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_customer: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_customer2(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_customer2: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  get_user_history(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        get_user_history: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  close_service(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        close_service: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_admin(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_admin: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  edit_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        edit_customer: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_customer(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_customer: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  reschedule_service(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        reschedule_service: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  delete_entry(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        delete_entry: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  load_history(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        load_history: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }

  add_service(data) {
    return fetch(api_link, {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data,
        add_service: "1",
        access_key: access_key,
      }),
    }).then((response) => response.json());
  }
}
